const superUsersById = {
  'auth0|5e5fd908b752c70d52f75faf': 'clairekim',
  'auth0|5e3b36aab661bc0e5912d831': 'chuck.flores',
  'auth0|5e2a03a47397b20e870ad385': 'admin',
  'auth0|5e406107521e060e7f178e3a': 'mattvanlaw',
  'auth0|6075d94786d33a0073cbb7e9': 'chrisyuan',
  'auth0|6089c8aa9b4c6c00710eb5b4': 'joseph.triolo',
  'auth0|60f0813ad611ca0070263869': 'liv.durling',
  'auth0|61424395966732007154a483': 'andrew.javidfar',
  'auth0|60de17baa9883b006a376824': 'jj-gong',
  'auth0|61b8f8da404cdc006944572f': 'rachel.zhou',
  'auth0|621fc10b19c0f0007054a3cc': 'eddie.huang',
  'auth0|62e94cf1767aaf5daca61246': 'conner.kim',
  'auth0|62fa4d2dfd94dde08e520278': 'jeainny.kim',
  'auth0|636c27c12c786b0db286f912': 'ronald.chang',
  'auth0|63e3d9b555d322e020fd0b07': 'dd_polaris',
  'auth0|645e5b8cfabee4079b685bf5': 'monique.grocia',
  'auth0|6595dac233a860f92e241862': 'anh.vuong',
}

export default superUsersById
